<template>
  <div>
  <button class="login-button" @click="showLoginModal = true">Login</button>
<div v-if="showLoginModal" class="modal">
  <div class="modal-content">
    <span class="close" @click="showLoginModal = false">&times;</span>
    <h2>Login</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="email">Email</label>
        <input v-model="loginData.email" type="email" required class="login-email-input" />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input v-model="loginData.password" type="password" required class="login-email-input" />
      </div>
      <button type="submit" class="submit-btn">Login</button>
    </form>
    <p v-if="error" class="error">{{ error }}</p>
    <!-- Forgot Password button -->
    <button class="forgot-password-btn" @click="openForgotPasswordModal">Forgot Password?</button>
  </div>
</div>

<!-- Forgot Password Modal -->
<div v-if="showForgotPasswordModal" class="modal">
  <div class="modal-content">
    <span class="close" @click="showForgotPasswordModal = false">&times;</span>
    <h2>Forgot Password</h2>
    <form @submit.prevent="sendResetCode">
      <div class="form-group">
        <label for="email">Enter your email</label>
        <input v-model="forgotPasswordData.email" type="email" required class="login-email-input" />
      </div>
      <button type="submit" class="submit-btn">Send Reset Code</button>
    </form>
    <p v-if="forgotPasswordError" class="error">{{ forgotPasswordError }}</p>
  </div>
</div>

<!-- Reset Password Modal -->
<div v-if="showResetPasswordModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showResetPasswordModal = false">&times;</span>
        <h2>Reset Password</h2>
        <form @submit.prevent="resetPassword">
          <div class="form-group">
            <label for="code">Confirmation Code</label>
            <input v-model="resetPasswordData.code" type="text" required class="login-email-input" />
          </div>
          <div class="form-group">
            <label for="newPassword">New Password</label>
            <input v-model="resetPasswordData.newPassword" type="password" required class="login-email-input" />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input v-model="resetPasswordData.confirmPassword" type="password" required class="login-email-input" />
          </div>
          <button type="submit" class="submit-btn">Reset Password</button>
        </form>
        <p v-if="resetPasswordError" class="error">{{ resetPasswordError }}</p>
      </div>
    </div>


  <div class="progress-bar-container">
      <button class="back-button" @click="prevStep">
        <!-- Back arrow icon -->
    <font-awesome-icon :icon="['fas', 'arrow-left']" />
      </button>

      <div class="logo-container">
        <img src="@/assets/icon.png" alt="Logo" class="logo" />
      </div>

      <div class="step-counter">
        {{ currentStep }}/15
      </div>
    </div>

    <!-- Progress Bar -->
    <div class="progress-bar">
      <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
    </div>

    <!-- Step 1: Categories -->
    <div v-if="currentStep === 1">
      <h2>Pick all job categories that interest you</h2>
      <div class="options">
        <button
          v-for="category in categories"
          :key="category.name"
          @click="selectCategory(category.name)"
          :class="{ selected: localData.categories.includes(category.name) }"
        >
          <font-awesome-icon :icon="category.icon" />
          {{ category.name }}
        </button>
      </div>
    </div>

    <!-- Step 2: Salary Range -->
<div v-if="currentStep === 2">
      <h2>Select your salary range</h2>
      <div class="salary-range">
        <!-- Vue Slider Component -->
        <VueSlider
          v-model="localData.salaryRange"
          :min="0"
          :max="200"
          range
          :tooltip="'always'"
          :formatter="formatCurrency"
          :dot-size="35"  
        />

        <!-- Displaying Min and Max Values -->
        <div class="salary-inputs">
          <div>
            <label>Min Salary: </label>
            <input type="text" :value="formatCurrency(localData.salaryRange[0])" disabled />
          </div>
          <div>
            <label>Max Salary: </label>
            <input type="text" :value="formatCurrency(localData.salaryRange[1])" disabled />
          </div>
        </div>
      </div>
    </div>


    <!-- Step 3: Experience -->
    <div v-if="currentStep === 3">
      <h2>Select your experience</h2>
      <div class="options">
        <button
          v-for="experience in experiences"
          :key="experience"
          @click="selectExperience(experience)"
          :class="{ selected: localData.experience === experience }"
        >
          {{ experience }}
        </button>
      </div>
    </div>

    <!-- Step 4: Experience Level -->
    <div v-if="currentStep === 4">
      <h2>Select your experience level</h2>
      <div class="options">
        <button
          v-for="level in experienceLevels"
          :key="level"
          @click="selectExperienceLevel(level)"
          :class="{ selected: localData.experienceLevel === level }"
        >
          {{ level }}
        </button>
      </div>
    </div>

    <!-- Step 5: Education Level -->
    <div v-if="currentStep === 5">
      <h2>Your Education Level</h2>
      <div class="options">
        <button
          v-for="education in educationLevels"
          :key="education"
          @click="selectEducation(education)"
          :class="{ selected: localData.education === education }"
        >
          {{ education }}
        </button>
      </div>
    </div>

    <!-- Step 6: Languages -->
    <div v-if="currentStep === 6">
      <h2>Your Preferred Language</h2>
      <div class="options">
        <button
          v-for="language in languages"
          :key="language"
          @click="selectLanguage(language)"
          :class="{ selected: localData.language === language }"
        >
          {{ language }}
        </button>
      </div>
    </div>

    <!-- Step 7: Employment Options -->
    <div v-if="currentStep === 7">
      <h2>Your Preferred Employment Options</h2>
      <div class="options">
        <button
          v-for="option in employmentOptions"
          :key="option"
          @click="selectEmploymentOption(option)"
          :class="{ selected: localData.employmentOptions.includes(option) }"
        >
          {{ option }}
        </button>
      </div>
    </div>

    <!-- Step 8: Availability -->
    <div v-if="currentStep === 8">
      <h2>Your Availability</h2>
      <div class="options">
        <button
          v-for="availability in availabilityOptions"
          :key="availability"
          @click="selectAvailability(availability)"
          :class="{ selected: localData.availability === availability }"
        >
          {{ availability }}
        </button>
      </div>
    </div>

    <!-- Step 9: Ideal Company Size -->
    <div v-if="currentStep === 9">
      <h2>Your ideal company size</h2>
      <div class="options">
        <button
          v-for="size in companySizes"
          :key="size"
          @click="selectCompanySize(size)"
          :class="{ selected: localData.companySize === size }"
        >
          {{ size }}
        </button>
      </div>
    </div>

    <!-- Step 10: Work-Life Balance or Professional Growth -->
    <div v-if="currentStep === 10">
      <h2>What’s more important for you today?</h2>
      <div class="options">
        <button
          v-for="priority in priorities"
          :key="priority"
          @click="selectPriority(priority)"
          :class="{ selected: localData.priority === priority }"
        >
          {{ priority }}
        </button>
      </div>
    </div>

    <!-- Step 11: Ideal Work Schedule -->
    <div v-if="currentStep === 11">
      <h2>Your ideal work schedule</h2>
      <div class="options">
        <button
          v-for="schedule in workSchedules"
          :key="schedule"
          @click="selectWorkSchedule(schedule)"
          :class="{ selected: localData.workSchedule === schedule }"
        >
          {{ schedule }}
        </button>
      </div>
    </div>

    <!-- Step 12: Ideal Team Setup -->
    <div v-if="currentStep === 12">
      <h2>Your ideal team setup</h2>
      <div class="options">
        <button
          v-for="teamSetup in teamSetups"
          :key="teamSetup"
          @click="selectTeamSetup(teamSetup)"
          :class="{ selected: localData.teamSetup === teamSetup }"
        >
          {{ teamSetup }}
        </button>
      </div>
    </div>

    <!-- Step 13: Time for Job Applications -->
    <div v-if="currentStep === 13">
      <h2>How much time do you have for job applications?</h2>
      <div class="options">
        <button
          v-for="option in timeOptions"
          :key="option"
          @click="selectTime(option)"
          :class="{ selected: localData.timeForApplications === option }"
        >
          {{ option }}
        </button>
      </div>
    </div>

    <!-- Step 14: Collect Email -->
    <!-- Step 14: Country Selection -->
<div v-if="currentStep === 14" class="country-selection-container">
  <h2>Which country will you work from?</h2>
  <select v-model="localData.country" @change="selectCountry">
    <option disabled value="">Select your country</option>
    <option v-for="(country, index) in countries" :key="index" :value="country">
      {{ country }}
    </option>
  </select>
  <p class="warning-text">If you’re a digital nomad, select the country where you pay taxes</p>
</div>


    <!-- Step 15: Collect Email -->
    <div v-if="currentStep === 15">
      <h2>Enter your email to complete the onboarding</h2>
      <input
        type="email"
        v-model="localData.email"
        placeholder="Enter your email"
        @input="validateEmail"
          class="onboarding-email-input"

      />
      <p v-if="!isEmailValid && localData.email">Please enter a valid email address.</p>
    </div>

    <!-- Navigation Buttons -->
    <div class="navigation">
      <button v-if="currentStep > 1" @click="prevStep">Back</button>
      <button v-if="currentStep < 15" @click="nextStep">Next</button>
      <button v-if="currentStep === 15" @click="submit" :disabled="!isEmailValid">Submit</button>
    </div>
    
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
// import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';

import {
  faUser,
  faHeadset,
  faKeyboard,
  faPhone,
  faUserTie,
  faLaptop,
  faCopy,
  faBriefcase,
  faLanguage,
  faChartLine,
  faBullhorn,
  faProjectDiagram,
  faHandshake,
  faSuitcase,
  faPaintBrush,
  faBlog,
  faBalanceScale,
  faPlane,
  faChalkboardTeacher,
  faHospital,
  faShoppingCart,
  faUserNurse,
//   faArrowLeft
} from '@fortawesome/free-solid-svg-icons';

export default {
  props: {
    onboardingData: Object,
  },
   components: {
    VueSlider, // Register the slider component
        FontAwesomeIcon,

  },
  data() {
    return {
       loginData: {
        email: '',
        password: ''
      },
            error: null,

        countries: [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
],
      marks: {
        0: '0',
        10: '10k',
        20: '20k',
        30: '30k',
        40: '40k',
        50: '50k',
        60: '60k',
        70: '70k',
        80: '80k',
        90: '90k',
        100: '100k',
      },
      currentStep: 1,
      totalSteps: 15,
      categories: [
        { name: 'Student', icon: faUser },
        { name: 'Customer Service', icon: faHeadset },
        { name: 'Data Entry', icon: faKeyboard },
        { name: 'Call Center', icon: faPhone },
        { name: 'Assistant', icon: faUserTie },
        { name: 'IT & Software', icon: faLaptop },
        { name: 'Copywriting', icon: faCopy },
        { name: 'Management', icon: faBriefcase },
        { name: 'Translator', icon: faLanguage },
        { name: 'Sales & BizDev', icon: faChartLine },
        { name: 'Marketing', icon: faBullhorn },
        { name: 'Operations', icon: faProjectDiagram },
        { name: 'Consulting', icon: faHandshake },
        { name: 'C-Suite', icon: faSuitcase },
        { name: 'Design', icon: faPaintBrush },
        { name: 'Influencer & Blogger', icon: faBlog },
        { name: 'Finance & Insurance', icon: faBalanceScale },
        { name: 'Travel Agent', icon: faPlane },
        { name: 'Education & Tutoring', icon: faChalkboardTeacher },
        { name: 'HR', icon: faUserTie },
        { name: 'Medical', icon: faHospital },
        { name: 'Procurement', icon: faShoppingCart },
        { name: 'Nursing', icon: faUserNurse },
      ],
      experiences: ['Less than 1 year', '1-3 years', '3-5 years', '5+ years'],
      experienceLevels: ['Entry', 'Mid', 'Senior'],
      educationLevels: ['High School', 'Associate Degree', "Bachelor's Degree", "Master's Degree", 'Doctorate'],
      languages: ['English', 'Spanish', 'French', 'German', 'Chinese'],
      employmentOptions: ['Full-time', 'Part-time', 'Contract', 'Freelance'],
      availabilityOptions: ['Immediately', 'In 1 month', 'In 3 months', 'Flexible'],
      companySizes: ['Startup', 'Mid-sized business', 'Corporation'],
      priorities: ['Work-life balance', 'Professional growth'],
      workSchedules: ['Flexible hours', '9 to 5'],
      teamSetups: ['Big teams', 'Small teams', 'Independent'],
      timeOptions: [
        "I'm too busy to apply for jobs", '10-30 min/day', '30-60 min/day', '1-2 hours/day', '2-4 hours/day'
      ],
      localData: {
        categories: this.onboardingData.categories || [],
        salaryRange: [40, 150],
        experience: this.onboardingData.experience || '',
        experienceLevel: this.onboardingData.experienceLevel || '',
        education: this.onboardingData.education || '',
        language: this.onboardingData.language || '',
        employmentOptions: this.onboardingData.employmentOptions || [],
        availability: this.onboardingData.availability || '',
        companySize: this.onboardingData.companySize || '',
        priority: this.onboardingData.priority || '',
        workSchedule: this.onboardingData.workSchedule || '',
        teamSetup: this.onboardingData.teamSetup || '',
        timeForApplications: this.onboardingData.timeForApplications || '',
        email: this.onboardingData.email || '',

      },
      isEmailValid: false,
      showLoginModal: false,
      showForgotPasswordModal: false,
      forgotPasswordData: {
        email: ''
      },
      forgotPasswordError: null,
      showResetPasswordModal: false,
      resetPasswordData: {
        code: '',
        newPassword: '',
        confirmPassword: ''
      },
      resetPasswordError: null,
      username: '', // For storing username
      password: '', // For storing password
      errorMessage: '', // For login errors
    };
  },
  computed: {
    // Dynamically calculate progress percentage based on current step
    progressPercentage() {
      return (this.currentStep / this.totalSteps) * 100;
    }
  },
  methods: {
  openForgotPasswordModal() {
    this.showLoginModal = false;
    this.showForgotPasswordModal = true;
  },

  // Send reset code to email
 async sendResetCode() {
      try {
        const response = await axios.post('https://www.remotejobsphere.com/api/password_resets', {
          email: this.forgotPasswordData.email.toLowerCase()
        });
        if (response.status === 200) {
          this.showForgotPasswordModal = false;
          this.showResetPasswordModal = true;
        }
      } catch (error) {
        this.forgotPasswordError = error.response.data.error || 'Error sending reset code. Please try again.';
      }
    },

    // Reset password
    async resetPassword() {
      if (this.resetPasswordData.newPassword !== this.resetPasswordData.confirmPassword) {
        this.resetPasswordError = 'Passwords do not match.';
        return;
      }

      try {
        const response = await axios.patch(`https://www.remotejobsphere.com/api/password_resets/${this.resetPasswordData.code}`, {
          email: this.forgotPasswordData.email.toLowerCase(),
          confirmation_code: this.resetPasswordData.code, // Pass confirmation code
          password: this.resetPasswordData.newPassword,
          password_confirmation: this.resetPasswordData.confirmPassword
        });

        if (response.status === 200) {
          this.showResetPasswordModal = false;
          alert('Password successfully reset. Please log in.');
        }
      } catch (error) {
        this.resetPasswordError = error.response.data.error || 'Failed to reset password. Check the confirmation code and try again.';
      }
    },
 




async login() {
  try {
    const response = await axios.post('https://www.remotejobsphere.com/api/login', this.loginData);

    if (response.data.user_id) {
      this.$store.dispatch('login'); // Dispatch the login action
      this.$router.push('/jobs'); // Redirect to jobs page
    } else {
      this.error = 'Login failed: Invalid response from the server.';
    }
  } catch (error) {
    this.error = 'Invalid email or password';
  }
},




    formatCurrency(value) {
      return `$${value}k`; // Customize this format based on your currency preference
    },
    selectCategory(category) {
      if (this.localData.categories.includes(category)) {
        this.localData.categories = this.localData.categories.filter((c) => c !== category);
      } else {
        this.localData.categories.push(category);
      }
    },
    selectExperience(experience) {
      this.localData.experience = experience;
    },
    selectExperienceLevel(level) {
      this.localData.experienceLevel = level;
    },
    selectEducation(education) {
      this.localData.education = education;
    },
    selectLanguage(language) {
      this.localData.language = language;
    },
    selectEmploymentOption(option) {
      const index = this.localData.employmentOptions.indexOf(option);
      if (index === -1) {
        this.localData.employmentOptions.push(option);
      } else {
        this.localData.employmentOptions.splice(index, 1);
      }
    },
    selectAvailability(availability) {
    console.log("Clicked availability:", availability); // Add this line for debugging
    this.localData.availability = availability;
  },
    selectCompanySize(size) {
      this.localData.companySize = size;
    },
    selectPriority(priority) {
      this.localData.priority = priority;
    },
    selectWorkSchedule(schedule) {
      this.localData.workSchedule = schedule;
    },
    selectTeamSetup(teamSetup) {
      this.localData.teamSetup = teamSetup;
    },
    selectTime(option) {
      this.localData.timeForApplications = option;
    },
    setEmail(email){
        this.localData.email = email;
    },
    nextStep() {
      if (this.currentStep < 15) {
        this.currentStep += 1;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailValid = emailPattern.test(this.localData.email);
    },
    submit() {
        
      if (!this.isEmailValid) return;
      localStorage.setItem('email', this.localData.email);

      this.$emit('update', this.localData);
      this.$emit('next-step');
    },
  },
};
</script>

<style scoped>
.login-email-input, 
input[type="password"], 
button.submit-btn {
  width: 100%;  /* Ensure they all occupy the same width */
  padding: 15px; /* Consistent padding */
  font-size: 1.2rem;
  margin-bottom: 20px; /* Ensure consistent spacing between elements */
  border-radius: 10px;  /* Consistent border radius for rounded corners */
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  box-sizing: border-box;  /* Ensure padding does not affect width */
}

/* Styles for the onboarding email input */
.onboarding-email-input {
 padding: 20px;
  width: 48%;
  margin: 20px 0;
  font-size: 1.5rem;
  border-radius: 10px;
  border: 3px solid #ccc;
}
.login-input {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.login-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  position: absolute;
  top: 10px;
  right: 25px;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.login-button:hover {
  background-color: #218838;
}

/* Modal Styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.form-input {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

button.submit-btn {
  padding: 15px 30px;
  background-color: #28a745;
  color: white;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
}

button.submit-btn:hover {
  background-color: #218838;
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

.close:hover {
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.submit-btn {
  padding: 10px 20px; /* Reduced padding for a smaller button */
  background-color: #28a745;
  color: white;
  font-size: 1rem; /* Adjust font size */
  border-radius: 5px; /* Rounded corners */
  border: none;
  cursor: pointer;
  width: auto; /* Adjust button width to fit content */
  text-align: center; /* Ensure the text is centered */
  display: inline-block;
}

.error {
  color: red;
  margin-top: 10px;
}
.country-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the dropdown and text */
  justify-content: center;
  margin-top: 20px; /* Adds spacing above the dropdown */
  width: 100%;
}

select {
  width: 60%; /* Increase width for better visibility */
  padding: 12px; /* Adds padding for better usability */
  font-size: 1.2rem; /* Adjusts font size */
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 10px; /* Adds spacing between the label and the dropdown */
}

p {
  margin-top: 10px; /* Adds space between the dropdown and the text */
  font-size: 1.2rem; /* Adjusts the font size of the text */
  color: #555; /* Softer look for the text */
  text-align: center; /* Center-aligns the text */
}

.warning-text {
  color: #ebd320; /* Softer red color */
  font-size: 1.1rem; /* Adjust font size */
  text-align: center; /* Center-align the warning text */
  margin-top: 10px; /* Adds space between the dropdown and the text */
}

.progress-bar-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure the back button and counter stay at the ends */
  padding: 10px 25%; /* Reduced padding to bring items closer to the center */
  background-color: white;
  position: relative;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #000;
  padding-left: 12px;  /* Increase space on the left side of the button for mobile */
  margin-right: 10px;
}

.logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.logo {
  width: 100px;
  height: 100px;
}

.step-counter {
  margin-left: auto; /* Keeps the step counter aligned to the right */
  font-size: 1.2rem;
  color: #333;
}

.progress-bar {
  height: 8px;
  background-color: #e0e0e0;
  margin: 0 25%;
  border-radius: 4px;
  position: relative;
}

.progress {
  height: 100%;
  background-color: #fdd835; /* Progress bar color */
  border-radius: 4px;
  transition: width 0.4s ease;
}


.salary-range {
  margin: 10px 30%; /* Adjusting margin to reduce space */
}

.salary-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 5px; /* Reduce margin between slider and input */
}

.salary-inputs div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.salary-inputs input {
  text-align: center;
  padding: 8px;  /* Reduce padding */
  font-size: 1rem;  /* Decrease font size */
  border: 1px solid #ccc;
  border-radius: 5px;
}

h2 {
  font-size: 2.5rem;
}

.options {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 40px;
  font-size: 1.5rem;
  border-radius: 20px;
  border: 2px solid #ccc;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.selected {
  background-color: #ffd700;
  color: #fff;
}

button:hover {
  background-color: #f0f0f0;
}

.salary-range {
  align-items: center;
  gap: 20px;
}

.salary-range input {
  padding: 15px;
  font-size: 1.5rem;
  border-radius: 10px;
  border: 2px solid #ccc;
  width: 80%;
}

.navigation {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.navigation button {
  padding: 20px 40px;
  font-size: 1.5rem;
  border-radius: 10px;
  border: none;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
}



p {
  font-size: 1.5rem;
  color: red;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-content {
    width: 80%; /* Make modal content smaller on smaller screens */
    padding: 30px;
  }

  .logo-container {
    width: 60%; /* Reduce logo container size on smaller screens */
  }

  .salary-range {
    width: 100%; /* Ensure the salary range takes full width */
    margin: 10px 0; /* Adjust the margin for spacing */
  }

  .salary-inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Ensure inputs are spaced evenly */
    width: 100%;
    margin-top: 10px; /* Add margin to avoid crowding */
  }

  .salary-inputs input {
    width: 48%; /* Adjust the input width to make room for both */
    padding: 10px;
    font-size: 1.2rem; /* Adjust font size for better readability */
  }

  .salary-range input {
    width: 85%; /* Make the range slider input take full width */
  }

  .options {
    flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
  }

  button {
    padding: 15px 25px; /* Adjust button size */
    font-size: 1.2rem;
  }

  .navigation button {
    padding: 15px 30px; /* Adjust navigation button size */
  }
}

</style>
