// src/store/index.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    isAuthenticated: !!localStorage.getItem('authToken'), // Check localStorage for the token
  },
  mutations: {
    setAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
  },
  actions: {
    login({ commit }, token) {
      localStorage.setItem('authToken', token); // Store token in localStorage
      commit('setAuthenticated', true);
    },
    logout({ commit }) {
      localStorage.removeItem('authToken'); // Remove token from localStorage
      commit('setAuthenticated', false);
    },
  },
});

export default store;
