<template>
  <div class="plan-selection-container">
<button class="login-button" @click="showLoginModal = true">Login</button>

    <!-- Login Modal -->
    <div v-if="showLoginModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showLoginModal = false">&times;</span>
        <h2>Login</h2>
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="email">Email</label>
            <input v-model="loginData.email" type="email" required class="login-email-input" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input v-model="loginData.password" type="password" required class="login-email-input" />
          </div>
          <button type="submit" class="submit-btn">Login</button>
        </form>
        <p v-if="error" class="error">{{ error }}</p>
        <button class="forgot-password-btn" @click="openForgotPasswordModal">Forgot Password?</button>
      </div>
    </div>

    <!-- Forgot Password Modal -->
    <div v-if="showForgotPasswordModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showForgotPasswordModal = false">&times;</span>
        <h2>Forgot Password</h2>
        <form @submit.prevent="sendResetCode">
          <div class="form-group">
            <label for="email">Enter your email</label>
            <input v-model="forgotPasswordData.email" type="email" required class="login-email-input" />
          </div>
          <button type="submit" class="submit-btn">Send Reset Code</button>
        </form>
        <p v-if="forgotPasswordError" class="error">{{ forgotPasswordError }}</p>
      </div>
    </div>

    <!-- Reset Password Modal -->
    <div v-if="showResetPasswordModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showResetPasswordModal = false">&times;</span>
        <h2>Reset Password</h2>
        <form @submit.prevent="resetPassword">
          <div class="form-group">
            <label for="code">Confirmation Code</label>
            <input v-model="resetPasswordData.code" type="text" required class="login-email-input" />
          </div>
          <div class="form-group">
            <label for="newPassword">New Password</label>
            <input v-model="resetPasswordData.newPassword" type="password" required class="login-email-input" />
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password</label>
            <input v-model="resetPasswordData.confirmPassword" type="password" required class="login-email-input" />
          </div>
          <button type="submit" class="submit-btn">Reset Password</button>
        </form>
        <p v-if="resetPasswordError" class="error">{{ resetPasswordError }}</p>
      </div>
    </div>

    <div class="left-section">
       <h1 class="main-title">Find a remote career today</h1>

      <div class="header-section2">
        <h2>Choose your plan</h2>
        <!-- <p class="discount-info">50% discount</p> -->
      </div>
      <div class="plan-options">
        <div
          class="plan"
          v-for="plan in plans"
          :key="plan.id"
          :class="{ popular: plan.popular }"
          @click="redirectToStripe(plan.price_id)" 
        >
        
          <div class="plan-header">
            <div v-if="plan.popular" class="most-popular-banner">
            <span>Most Popular</span>
          </div>
            <h3>{{ plan.name }}</h3>
            <div class="price-container">
              <p class="price-before">{{ plan.originalPrice }}</p>
              <p class="price-now">{{ plan.discountedPrice }}</p>
              <p class="price-day">{{ plan.pricePerDay }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-section">
       
        <div class="payment-icons">
          <img src="https://planselectionimages.s3.eu-west-1.amazonaws.com/Visa_2021.svg.png" alt="Visa" />
          <img src="https://planselectionimages.s3.eu-west-1.amazonaws.com/Mastercard_2019_logo.svg.png" alt="Mastercard" />
          <img src="https://planselectionimages.s3.eu-west-1.amazonaws.com/PayPal.svg" alt="Paypal" />
          <img src="https://planselectionimages.s3.eu-west-1.amazonaws.com/American_Express_logo.svg" alt="Amex" />
        </div>
        <div class="free-remote-button" @click="toggleFreeForm">
          Free Remote Jobs
        </div>
         <div v-if="showFreeForm" class="free-form-container">
          <div class="form-header">
            <h3>Sign up to access our free remote job board</h3>
            <button type="button" class="close-button" @click="closeFreeForm">X</button>
          </div>
          <form @submit.prevent="submitFreeForm">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" v-model="freeFormData.email" id="email" required>
            </div>
            <div class="form-group">
              <label for="firstName">First Name</label>
              <input type="text" v-model="freeFormData.firstName" id="firstName" required>
            </div>
            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input type="text" v-model="freeFormData.lastName" id="lastName" required>
            </div>
            <!-- <div class="form-group">
              <label for="cv">CV</label>
              <input type="file" @change="handleFileUpload" id="cv" required>
            </div> -->
            <button type="submit" class="submit-button">Submit</button>
          </form>
        </div>

      </div>
    </div>
    <div class="right-section">
<img :src="require('@/assets/man2.png')" alt="Promo Image" class="promo-image" />
  <h2 class="brand-title">We scrape the internet for all of the latest remote opportunites and bring them under one roof</h2>

    </div>
  </div>
  <div class="company-logos-section">
    <h2>Our members got jobs at these companies</h2>
    <div class="company-logos">
      <div class="company-logo" v-for="company in companies" :key="company.name">
        <img :src="company.logo" :alt="company.name" />
      </div>
    </div>
  </div>
  <div class="testimonial-section">
    <h2>Our members love it</h2>
    <div class="carousel-container">
      <button @click="prevSlide" class="arrow left-arrow">&#10094;</button>
      
      <div class="carousel">
        <div 
          class="testimonial" 
          v-for="(testimonial, index) in visibleTestimonials" 
          :key="index"
        >
          <img :src="testimonial.avatar" alt="User Avatar" class="avatar" />
          <div class="rating">
            <span v-for="star in 5" :key="star" class="star">&#9733;</span>
          </div>
          <p class="username">{{ testimonial.username }} wrote a review for RemoteJobSphere</p>
          <p class="review">{{ testimonial.review }}</p>
        </div>
      </div>
      
      <button @click="nextSlide" class="arrow right-arrow">&#10095;</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      showLoginModal: false,
    showForgotPasswordModal: false,
    showResetPasswordModal: false,
    loginData: {
      email: '',
      password: '',
    },
    forgotPasswordData: {
      email: '',
    },
    resetPasswordData: {
      code: '',
      newPassword: '',
      confirmPassword: '',
    },
    error: null,
    forgotPasswordError: null,
    resetPasswordError: null,
      selectedPlan: "",
       showFreeForm: false,
      freeFormData: {
        email: '',
        firstName: '',
        lastName: '',
        cv: null
      },
      testimonials: [
      {
        username: "Raj",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/man1.jpg",
        review: "I found a high-paying remote job in 3 weeks! It's like your own job-hunting genie. Thx!!",
      },
      {
        username: "Viktoria",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/woman1.jpg",
        review: "This is the best tool I’ve used for job searching.",
      },
      {
        username: "Aarav",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/man2.jpg",
        review: "Great website to hunt for hidden remote jobs. And they filter out all job scams and ad.",
      },
      {
        username: "Marie",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/woman2.jpg",
        review: "I love how easy it was to find my dream remote job using RemoteJobSphere!",
      },
      {
        username: "John",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/man3.jpg",
        review: "A fantastic resource for remote job seekers, highly recommend!",
      },
      {
        username: "Sophia",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/woman3.jpg",
        review: "RemoteJobSphere made job hunting so much easier. I got hired within two weeks!",
      },
      {
        username: "Liam",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/man4.jpg",
        review: "An awesome platform for finding legit remote jobs. It saved me a lot of time and effort.",
      },
      {
        username: "Emma",
        avatar: "https://planselectionimages.s3.eu-west-1.amazonaws.com/woman4.jpg",
        review: "I've recommended RemoteJobSphere to all my friends. It’s a game-changer for remote work opportunities!",
      },
    ],
      currentIndex: 0,
      companies: [
        { name: "Amazon", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Amazon_logo.svg" },
        { name: "GitHub", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Octicons-mark-github.svg" },
        { name: "Uber", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Uber_logo_2018.png" },
        { name: "Google", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Google_2015_logo.svg" },
        { name: "Slack", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Slack_Icon.png" },
        { name: "Spotify", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Spotify_logo_without_text.svg" },
        { name: "Zoom", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Zoom_Communications_Logo.svg" },
        { name: "Tesla", logo: "https://planselectionimages.s3.eu-west-1.amazonaws.com/Tesla_Motors.svg" },
      ],
      plans: [
        {
          id: 1,
          name: "1-Week Plan",
          originalPrice: "€2.00",
          discountedPrice: "€1.00",
          pricePerDay: "per day",
          popular: false,
          // price_id: "price_1Q6dNyGYUFZ9bd1e0yupQEEm",
          price_id: "price_1Q6dBkGYUFZ9bd1eDvWy4NGJ"

        },
        {
          id: 2,
          name: "1-Month Plan",
          originalPrice: "€1.90",
          discountedPrice: "€0.90",
          pricePerDay: "per day",
          popular: true, // This one will be highlighted as most popular
          // price_id: "price_1Q6dNyGYUFZ9bd1e0yupQEEm",
          price_id: "price_1QJd08GYUFZ9bd1eRSsZKsFy"         
        },
        {
          id: 3,
          name: "3-Month Plan",
          originalPrice: "€1.70",
          discountedPrice: "€0.85",
          pricePerDay: "per day",
          popular: false,
          // price_id: "price_1Q6dNyGYUFZ9bd1e0yupQEEm",
          price_id: "price_1QJd2yGYUFZ9bd1e9rgmDXVj"
        },
      ],
    };
  },
   computed: {
    visibleTestimonials() {
      return [
        this.testimonials[this.currentIndex],
        this.testimonials[(this.currentIndex + 1) % this.testimonials.length],
        this.testimonials[(this.currentIndex + 2) % this.testimonials.length],
      ];
    },
  },
  methods: {
    openForgotPasswordModal() {
    this.showLoginModal = false;
    this.showForgotPasswordModal = true;
  },

  // Send reset code to email
  async sendResetCode() {
    try {
      const response = await axios.post('https://www.remotejobsphere.com/api/password_resets', {
        email: this.forgotPasswordData.email.toLowerCase(),
      });
      if (response.status === 200) {
        this.showForgotPasswordModal = false;
        this.showResetPasswordModal = true;
      }
    } catch (error) {
      this.forgotPasswordError = error.response.data.error || 'Error sending reset code. Please try again.';
    }
  },

  // Reset password
  async resetPassword() {
    if (this.resetPasswordData.newPassword !== this.resetPasswordData.confirmPassword) {
      this.resetPasswordError = 'Passwords do not match.';
      return;
    }

    try {
      const response = await axios.patch(
        `https://www.remotejobsphere.com/api/password_resets/${this.resetPasswordData.code}`,
        {
          email: this.forgotPasswordData.email.toLowerCase(),
          password: this.resetPasswordData.newPassword,
          password_confirmation: this.resetPasswordData.confirmPassword,
        }
      );

      if (response.status === 200) {
        this.showResetPasswordModal = false;
        alert('Password successfully reset. Please log in.');
      }
    } catch (error) {
      this.resetPasswordError =
        error.response.data.error || 'Failed to reset password. Check the confirmation code and try again.';
    }
  },

  // Login functionality
  async login() {
    try {
      const response = await axios.post('https://www.remotejobsphere.com/api/login', this.loginData);
      if (response.data.user_id) {
        this.$store.dispatch('login'); // Dispatch the login action
        this.$router.push('/jobs'); // Redirect to jobs page
      } else {
        this.error = 'Login failed: Invalid response from the server.';
      }
    } catch (error) {
      this.error = 'Invalid email or password';
    }
  },
     toggleFreeForm() {
      gtag('event', 'button_click', {
      event_category: 'User Interaction',
      event_label: 'USER CLICKED FREE JOBS BUTTON',
      value: 1 // Optional, for numeric value tracking
    });
    this.showFreeForm = !this.showFreeForm;
  },
      closeFreeForm() {
      this.showFreeForm = false;
    },
    handleFileUpload(event) {
      this.freeFormData.cv = event.target.files[0];
    },
     async submitFreeForm() {
      try {
        // Prepare user data
        const userData = {
          email: this.freeFormData.email,
          first_name: this.freeFormData.firstName,
          last_name: this.freeFormData.lastName,
          password: 'Welcome1!'
        };

        // Post request to create the user
        const response = await axios.post(
          'https://www.remotejobsphere.com/api/users', 
          { user: userData },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );

        console.log('User created:', response.data);

        // After successful user creation, navigate to the FreeRemoteJobs page
        this.$router.push({ name: 'FreeRemoteJobs' });

      } catch (error) {
        console.error("Error creating user:", error);
      }
    },
     nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.testimonials.length) %
        this.testimonials.length;
    },
    async redirectToStripe(priceId) {
       gtag('event', 'button_click', {
      event_category: 'User Interaction',
      event_label: 'USER CLICKED STRIPE BUTTON',
      value: 1 // Optional, for numeric value tracking
    });
      try {
        const response = await axios.post(
          "https://www.remotejobsphere.com/api/checkout/create_session",
          {
            price_id: priceId,
          },{
              headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          } 
        );
        window.location.href = response.data.url;
      } catch (error) {
        console.error("Error creating Stripe checkout session:", error);
      }
    },
  },
};
</script>

<style scoped>
.login-button {
  background-color: #6c00f0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  position: absolute;
  top: 30px;
  right: 25px;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.login-button:hover {
  background-color: #4d04a0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker background for better contrast */
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  position: relative;
  animation: slide-down 0.3s ease; /* Add an animation for modal appearance */
}

@keyframes slide-down {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5rem;
  color: #888;
  cursor: pointer;
}

.close:hover {
  color: #333;
}

h2 {
  font-size: 2rem;
  color: #6c00f0; /* Matches your brand color */
  margin-bottom: 20px;
  font-weight: bold;
}

.login-email-input {
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

button.submit-btn {
  background-color: #6c00f0;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.submit-btn:hover {
  background-color: #5a00c8;
}

.forgot-password-btn {
  margin-top: 10px;
  background: none;
  border: none;
  color: #007bff;
  font-size: 0.9rem;
  cursor: pointer;
}

.forgot-password-btn:hover {
  text-decoration: underline;
  color: #0056b3;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}


.free-remote-button {
  margin-top: 20px;
  border: 2px solid white;
  border-radius: 10px;
  padding: 15px 20px;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-width: 70%;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Add hover and focus states for a nice interactive feel */
.free-remote-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);
}

/* Title below the image */
.brand-title {
  position: absolute;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  /* Adjust top/left values to place the text where the laptop screen is */
  top: 100%;   
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}


.main-title {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.free-remote-button {
  margin-top: 20px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.free-form-container {
  background: #ffffff;
  color: #333;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.free-form-container h3 {
  margin-bottom: 20px;
}

.form-group {
  width: 97%;
  margin-bottom: 15px;
  text-align: left;
  color: black;
}
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  line-height: 1;
  padding: 5px;
}

.close-button:hover {
  color: #6c00f0;
}

.form-header h3 {
  margin-bottom: 20px;
  margin-top: 0;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="email"],
.form-group input[type="text"],
.form-group input[type="file"] {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.submit-button {
  background-color: #6c00f0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}
.submit-button:hover {
  background-color: #5a00c8;
}
.testimonial-section {
  text-align: center;
  padding: 40px;
  
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  display: flex;
  overflow: hidden;
  width: 70%;
}

.testimonial {
  flex: 1;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.rating {
  color: #00d283;
  margin-bottom: 10px;
}

.star {
  font-size: 1.2rem;
}

.username {
  font-weight: bold;
  margin-bottom: 5px;
}

.review {
  font-size: 1rem;
  color: #666;
  font-style: italic;
}

.arrow {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  color: #333;
}

.left-arrow {
  margin-right: 20px;
}

.right-arrow {
  margin-left: 20px;
}

.arrow:hover {
  color: #00d283;
}

.plan-selection-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  background-color: #6c00f0;
  color: white;
  font-family: Arial, sans-serif;
}

.left-section {
  flex: 1;
}

.right-section {
  position: relative;
  /* flex: 1; */
}

.promo-image {
  max-width: 65%;
  height: auto;
}

/* Header Section */
.header-section {
  text-align: left;
  margin-bottom: 20px;
}

.header-section2 h2 {
    text-align: left;

  color: white; /* This makes the header text white */
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

.discount-info {
  background-color: #00d283;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 1.25rem;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}

/* Plan Options */
.plan-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.plan {
  border: 2px solid white;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  max-width: 70%;
  cursor: pointer; /* Makes the plan act like a button */
  position: relative;
}

.most-popular-banner {
  background-color: #ffffff;
  color: #6c00f0;
  font-weight: bold;
  /* padding: 5px 10px; */
  border-radius: 8px 8px 0 0;
  position: absolute;
  top: -1px; /* Adjust this to position the banner correctly */
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.plan:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(255, 255, 255, 0.1);
}

.plan-header {
    display: flex; /* Use flexbox to align items horizontally */
    justify-content: space-between; /* Distribute the space between the plan name and the price container */
    align-items: center; /* Align the items vertically in the center */
    width: 100%; /* Make sure the content spans the whole container */
}

.plan-header h3 {
  font-size: 2.9rem;
  font-weight: bold;
  color: white;
  margin: 0;
}

.price-container {
  text-align: left;
  display: flex;
  flex-direction: column; /* Align elements vertically */
  /* gap: 1px;  */
}

.price-before {
  text-decoration: line-through;
  font-size: 0.8rem;
  color: #f1f1f1;
}

.price-now {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-top: -12px;
  margin-bottom: -12px;

}

.price-day {
  font-size: 0.8rem;
  color: #f1f1f1;
}

/* Footer Section */
.footer-section {
  text-align: left;
  margin-top: 20px;
}

.payment-info {
  font-size: 0.9rem;
  color: white;
}

.payment-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.payment-icons img {
  height: 30px;
}

/* Company Logos Section */
.company-logos-section {
  padding: 50px 20px;
  background-color: #f7f9fc;
  text-align: center;
}

.company-logos-section h2 {
  font-size: 2rem;
  margin-bottom: 30px;
  color: #333;
}

.company-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.company-logo {
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 10px;
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.company-logo img {
  max-width: 100%;
  max-height: 60px;
}

.company-logo:hover {
  transform: scale(1.05);
}

/* Adjust text size for smaller screens */
@media (max-width: 1700px) {
  .plan-header h3 {
    font-size: 2.4rem; /* Make the plan name smaller */
  }

  .price-container p {
    font-size: 1rem; /* Adjust price text size */
  }

.payment-info {
    font-size: 1rem; /* Adjust font size */
    width: 90%; /* Make the payment info section narrower */
    text-align: left; /* Optionally center align the text */
  }

  .right-section {
    display: none; /* Hide the right section */
  }

  /* Payment Icons Section */
  .payment-icons {
    display: flex;
    justify-content: left; /* Center the icons */
    align-items: center; /* Center the icons vertically */
    gap: 10px; /* Adjust the gap between icons */
    width: 100%; /* Make the payment icons section narrower */
    margin: 0 auto; /* Center align the icons */
    margin-top: 10px;
    flex-wrap: wrap; /* Allow the icons to wrap onto the next line if necessary */
  }

  .payment-icons img {
    height: 30px; /* Maintain icon size */
  }
  .plan {
    width: 100%;  /* Increase the width of the plan on mobile screens */
    max-width: 80%;
  }
}

</style>
