<template>
  <div class="job-detail">
    <!-- Styled back button -->
    <button class="submit-button" @click="$router.push('/plans')">Back to all jobs</button>

    <div class="job-container">
      <!-- Left column: Job information -->
      <div class="job-info">
        <h1>Frontend Developer - Automotive Tech Company – Amsterdam, Netherlands</h1>
        <div class="job-tags">
          <span class="job-tag">Frontend</span>
          <span class="job-tag">Angular</span>
          <span class="job-tag">Amsterdam</span>
          <span class="job-tag">Remote</span>
        </div>

        <h2 class="time-posted">Posted: Just now</h2>

        <!-- Render the description -->
        <div class="job-description">
          <p>Renowned for revolutionizing the automotive industry with cutting-edge technology and innovation, our client is a globally recognized leader in connected vehicle solutions, having delivered over 10 million advanced systems and enabled billions of miles of data-driven journeys. We are currently seeking a talented Frontend Developer to join our team and shape the future of our pioneering automotive tech projects!</p>
          <p>In this role, you will have the opportunity to work across the full project lifecycle, driving impactful solutions for our industry-leading platform. Frontend Developer applicants should bring expertise in Angular, Angular.js, TypeScript, and Agile methodologies. Our client’s tech stack also includes exposure to tools like RxJs, HTML, CSS, JavaScript, jQuery, React, VueJS, Web, UI, UX, User Interface, User Experience, and Front End technologies.</p>
          <p>This is an exceptional chance to join a forward-thinking company offering an innovative environment, ongoing professional development, a structured career growth path, flexible working arrangements, remote working options, and state-of-the-art facilities.</p>
          <p><strong>Location:</strong> Amsterdam, Netherlands / Remote Working</p>
          <p><strong>Salary:</strong> €5,500 - €8,100 + Bonus + Benefits</p>
        </div>

        <a href="https://www.remotejobsphere.com" class="apply-button" target="_blank">Apply for this position</a>
      </div>

      <!-- Right column: Company information -->
      <div class="company-info">
        <div class="company-card">
          <h3>Automotive Tech Company</h3>
          <a href="https://www.remotejobsphere.com" class="company-website" target="_blank">Company Website</a>
          <h3 class="location">Location: Amsterdam, Netherlands</h3>
          <p>Jobs Posted: 6</p>

        </div>
        <a href="https://www.remotejobsphere.com" class="apply-button-side" target="_blank">Apply for this position</a>
        <a class="view-all-jobs" href="https://www.remotejobsphere.com">View company profile & all jobs</a>
         <button class="remote-jobs-button" @click="goToRemoteJobs">Find More Jobs</button>

      </div>
    </div>

    <!-- Sign-Up Form Section -->
    <div class="signup-section">
      <h2>Apply for this Job</h2>
      <form @submit.prevent="submitApplication" class="signup-form">
        <div class="form-group">
          <label for="email">E-mail*</label>
          <input v-model="applicationData.email" type="email" id="email" placeholder="Your e-mail" required />
        </div>
        <div class="form-row">
          <div class="form-group">
            <label for="firstName">First Name*</label>
            <input v-model="applicationData.firstName" type="text" id="firstName" placeholder="Your first name" required />
          </div>
          <div class="form-group">
            <label for="lastName">Last Name*</label>
            <input v-model="applicationData.lastName" type="text" id="lastName" placeholder="Your last name" required />
          </div>
        </div>
        <div class="form-group">
          <label for="phone">Phone Number</label>
          <input v-model="applicationData.phone" type="tel" id="phone" placeholder="Your phone number" />
        </div>
        <div class="form-group">
          <label for="linkedin">Link to LinkedIn</label>
          <input v-model="applicationData.linkedin" type="url" id="linkedin" placeholder="Add a link" />
        </div>
        <div class="form-group">
        <label for="resume">Resume*</label>
        <input
            @change="handleFileUpload"
            type="file"
            id="resume"
            accept=".doc,.docx,.pdf"
            required
        />
        </div>
        <div class="form-group">
          <label for="additionalInfo">Tell us about yourself</label>
          <textarea v-model="applicationData.additionalInfo" id="additionalInfo" rows="3" placeholder="Your skills and ambitions"></textarea>
        </div>
        <button type="submit" class="submit-button">Submit Application</button>
      </form>
    </div>

    <div v-if="showPopup" class="popup-overlay">
    <div class="popup-content">
        <h3>Thank You for Applying!</h3>
        <p>We will get back to you within 7 working days</p>
        <button class="close-popup" @click="closePopup">Close</button>
        <!-- New Signup Button -->
        <button class="signup-button" @click="redirectToPlans">Signup</button>
    </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: 'FrontendDeveloperAmsterdam',
  data() {
    return {
      applicationData: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        linkedin: '',
        resume: null,
        additionalInfo: '',
      },
      showPopup: false,

    };
  },
  methods: {
    goToRemoteJobs() {
      this.$router.push('/plans'); // Navigate to a new "Remote Jobs" page
    },
    handleFileUpload(event) {
    this.applicationData.resume = event.target.files[0]; // Store the file in applicationData
    },

   async submitApplication() {
  try {
    // Create a FormData object
    const formData = new FormData();
    formData.append("applicant[email]", this.applicationData.email);
    formData.append("applicant[first_name]", this.applicationData.firstName);
    formData.append("applicant[last_name]", this.applicationData.lastName);
    formData.append("applicant[phone]", this.applicationData.phone);
    formData.append("applicant[linkedin]", this.applicationData.linkedin);
    formData.append("applicant[additional_info]", this.applicationData.additionalInfo);

    // Append the resume file if it exists
    // if (this.applicationData.resume) {
    //   formData.append("applicant[resume]", this.applicationData.resume);
    // }

    // Send the FormData to the backend
    const response = await axios.post(
      "https://www.remotejobsphere.com/api/applicants", // Your backend endpoint
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Application submitted successfully:", response.data);
  } catch (error) {
    console.error("Error submitting application:", error);
  }
  this.showPopup = true;

},


closePopup() {
  this.showPopup = false; // Close the popup
},
 redirectToPlans() {
    this.$router.push('/plans'); // Navigate to the /plans page
  },
  },
};
</script>

<style scoped>
.signup-button {
  background-color: #6c00f0; /* Purple color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
}

.signup-button:hover {
  background-color: #6c00f0; /* Darker purple on hover */
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.popup-content h3 {
  margin-bottom: 10px;
}

.popup-content p {
  margin-bottom: 20px;
}

.close-popup {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-popup:hover {
  background-color: #218838;
}

.job-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
  }

  .job-tag {
    font-size: 0.9rem; /* Make the tags smaller for mobile */
    padding: 6px 12px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
.remote-jobs-button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #6c00f0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
}

.remote-jobs-button:hover {
  background-color: #36017b;
}
/* Preserve the original styling */
.job-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

.job-container {
  display: flex;
  gap: 20px;
}

.job-info {
  flex: 2;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}

.company-info {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
}

.signup-section {
  margin-top: 40px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: left;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: left;
}

textarea {
  resize: none;
  text-align: left;
}

.submit-button {
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #218838;
}

.form-row {
  display: flex;
  gap: 40px;
}
</style>
