<template>
  <div class="success-page">
    <h1>Payment Successful!</h1>
    <p>Your payment has been processed successfully. Please set your details to complete your profile:</p>

    <!-- Editable Email Field -->
    <div class="form-group">
      <label for="email">Email</label>
      <input
        type="email"
        v-model="email"
        placeholder="Enter your email"
        required
        class="form-input"
      />
    </div>

    <!-- Password Fields -->
    <div class="form-group">
      <label for="password">Password</label>
      <input
        type="password"
        v-model="password"
        placeholder="Enter your password"
        required
        class="form-input"
      />
    </div>
    <div class="form-group">
      <label for="passwordConfirmation">Confirm Password</label>
      <input
        type="password"
        v-model="passwordConfirmation"
        placeholder="Confirm your password"
        required
        class="form-input"
      />
    </div>

    <!-- Single Button -->
    <button @click="setUserDetails" class="submit-btn">Set User Details</button>

    <p v-if="error" class="error">{{ error }}</p>
    <p v-if="successMessage" class="success">{{ successMessage }}</p>

    <button @click="goToJobs" v-if="passwordSet" class="submit-btn">Go to Jobs List</button>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      userId: "",
      password: "",
      passwordConfirmation: "",
      error: null,
      successMessage: "",
      passwordSet: false,
    };
  },
  methods: {
    async setUserDetails() {
      try {
        // Clear previous error/success messages
        this.error = null;
        this.successMessage = null;

        // Fetch user ID if not already set
        if (!this.userId) {
          const response = await axios.get(
            "https://www.remotejobsphere.com/api/users/find_by_email",
            { params: { email: this.email } }
          );

          if (response.data && response.data.id) {
            this.userId = response.data.id;
          } else {
            this.error = "User not found.";
            return;
          }
        }

        // Set password for the user
        const updateResponse = await axios.put(
          `https://www.remotejobsphere.com/api/users/${this.userId}`,
          {
            user: {
              password: this.password,
              password_confirmation: this.passwordConfirmation,
            },
          }
        );

        if (updateResponse.status === 200) {
          this.successMessage = "User details updated successfully!";
          this.passwordSet = true;
        }
      } catch (error) {
        console.error("Error updating user details:", error);
        this.error =
          "An error occurred while updating your details. Please try again.";
      }
    },
    goToJobs() {
      this.$router.push("/jobs");
    },
  },
  mounted() {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      this.email = storedEmail;
    }
  },
};
</script>


<style scoped>
.fetch-user-btn {
  padding: 20px 40px;
  font-size: 1.5rem;
  border-radius: 10px;
  border: none;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
}

.fetch-user-btn:hover {
  background-color: #218838;
}
.success-page {
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #555;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

label {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.form-input {
  padding: 20px;
  width: 60%;
  font-size: 1.5rem;
  border-radius: 10px;
  border: 2px solid #ccc;
}

.submit-btn {
  padding: 20px 40px;
  font-size: 1.5rem;
  border-radius: 10px;
  border: none;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
}

.submit-btn:hover {
  background-color: #218838;
}

p.error {
  color: red;
}

p.success {
  color: green;
}
</style>
